import React from 'react'
import { PageProps } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'

import Layout from '../components/Layout'
import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'
import Meta from '../components/Meta'

const Versand: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Versand" />
      <PageSection>
        <SectionHeaderRow headline="Versand" />
        <div className="row">
          <div className="col-12">
            <h4>Versand</h4>
            <p>
              Der Versand erfolgt ausschließlich nach Deutschland. Ab einem
              Bestellwert von 1000€ ist der Versand kostenfrei. Die Kosten für
              den versicherten Versand betragen 30€.
            </p>
            <p>
              Der Versand erfolgt in der Regel innerhalb von drei bis fünf
              Werktagen.
            </p>
            <p>
              Sobald du deine Ware erhalten hast, bitten wir dich, den Zustand
              der Ware zu prüfen und eventuelle Schäden an der Verpackung vom
              Zusteller bestätigen zu lassen.
            </p>
            <h4>Rückversand</h4>
            <p>
              Sollte dir die Ware nicht gefallen oder solltest du beschädigte
              Ware erhalten haben, melde dich bitte unverzüglich unter dem
              Kontakt Formular.
            </p>
            <p>
              Weitere Informationen findest du in unserer Widerrufsbelehrung und
              unseren AGB's.
            </p>
          </div>
        </div>
      </PageSection>
    </Layout>
  )
}

export default Versand
